<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <div class="mt-4 d-flex align-center justify-center flex-column">
        <v-tabs
          style="width: 60%"
          v-model="tab"
          background-color="transparent"
          color="#052633"
          grow
        >
          <v-tab @click="fetchData()" class="text-capitalize">
            Manajemen Gaji
          </v-tab>
          <v-tab @click="fetchDataGajiToday()" class="text-capitalize">
            List Pembayaran Hari Ini
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div>
          <v-row no-gutters class="mt-2 px-3" align="center">
            <v-col cols="12" md="4" class="py-2">
              <div class="d-flex align-center">
                <bullet :title="'List Gaji'" class="mr-2" />
                <v-menu transition="slide-y-transition" bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      @click="importExport(import_data)"
                      class="bg_color1 rounded-lg"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <div class="d-flex align-center text-capitalize">
                        <v-icon class="mr-1">
                          {{ import_data ? "mdi-import" : "mdi-export" }}
                        </v-icon>
                        {{ import_data ? "Import" : "Export" }}
                        <v-icon class="ml-1"> mdi-menu-down </v-icon>
                      </div>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item class="px-2">
                      <v-btn
                        block
                        depressed
                        @click="changeFunction('export')"
                        class="rounded-lg"
                      >
                        <div class="d-flex align-center text-capitalize">
                          <v-icon class="mr-1">mdi-export</v-icon>
                          Export
                        </div>
                      </v-btn>
                    </v-list-item>
                    <v-list-item class="px-2">
                      <v-btn
                        block
                        depressed
                        @click="changeFunction('import')"
                        class="rounded-lg"
                      >
                        <div class="d-flex align-center text-capitalize">
                          <v-icon class="mr-1">mdi-import</v-icon>
                          Import
                        </div>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <v-row no-gutters align="center" justify="end">
                <div class="col_tollbar">
                  <v-btn
                    depressed
                    dark
                    class="text-capitalize btn_tollbar rounded-lg bg_color1"
                    @click="createData"
                  >
                    <v-icon class="mr-2" dark> mdi-plus </v-icon>
                    Tambah
                  </v-btn>
                </div>
                <div class="col_tollbar">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="sort_menu"
                    transition="slide-y-transition"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        dark
                        color="#CFE1E1"
                        class="text-capitalize btn_tollbar rounded-lg"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="#106967"> {{ icon_sort }}</v-icon>
                        <h4 class="ml-2" style="color: #106967">
                          {{
                            sort
                              ? sort == "karyawan_id"
                                ? "Karyawan Id"
                                : "Tangggal Berlaku"
                              : "Sort"
                          }}
                        </h4>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <div class="d-flex align-center">
                          <v-btn
                            @click="
                              changedesc(
                                icon_sort == 'mdi-sort-ascending'
                                  ? 'mdi-sort-descending'
                                  : 'mdi-sort-ascending'
                              )
                            "
                            block
                            class="text-capitalize"
                            text
                          >
                            <v-icon class="mr-2">{{ icon_sort }}</v-icon>
                            Sort
                          </v-btn>
                        </div>
                        <v-btn @click="sort_menu = false" icon fab x-small>
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn
                          block
                          :class="
                            sort == 'karyawan_id'
                              ? 'text-capitalize grey'
                              : 'text-capitalize white'
                          "
                          :dark="sort == 'karyawan_id' ? true : false"
                          text
                          @click="changeSort('karyawan_id')"
                        >
                          Karyawan Id
                        </v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn
                          block
                          :class="
                            sort == 'effective_date'
                              ? 'text-capitalize grey'
                              : 'text-capitalize white'
                          "
                          :dark="sort == 'effective_date' ? true : false"
                          text
                          @click="changeSort('effective_date')"
                        >
                          Tanggal Berlaku
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-row>
            </v-col>
          </v-row>
          <v-skeleton-loader
            class="pa-2 elevation-0"
            type="table-tbody"
            v-if="loading"
          ></v-skeleton-loader>
          <div v-if="!loading && dataSet">
            <v-data-table
              :headers="headers"
              :items="dataSet"
              hide-default-footer
              :page.sync="page"
              :items-per-page="limit"
              class="elevation-0"
              :single-select="false"
            >
              <template v-slot:[`item.status_bayar`]="{ item }">
                <v-card
                  flat
                  :color="
                    item.status_bayar == 0
                      ? '#DC3545'
                      : item.status_bayar == 1
                      ? '#EDD57B'
                      : '#26AF65'
                  "
                  dark
                  class="text-capitalize pa-2 d-flex align-center justify-center"
                >
                  {{
                    item.status_bayar == 0
                      ? "Belum"
                      : item.status_bayar == 1
                      ? "Tertunda"
                      : "Bayar"
                  }}
                </v-card>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        depressed
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="updateData(item)"
                      >
                        <v-icon small> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Data Gaji</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="item.status_bayar == 2 ? true : false"
                        v-bind="attrs"
                        v-on="on"
                        small
                        icon
                        depressed
                        @click="payData(item)"
                      >
                        <v-icon small> mdi-contactless-payment </v-icon>
                      </v-btn>
                    </template>
                    <span>Bayar Gaji Karyawan</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        icon
                        depressed
                        color="red"
                        @click="deleteData(item)"
                      >
                        <v-icon dark small> mdi-delete </v-icon>
                      </v-btn></template
                    >
                    <span>Hapus Akun Klien</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
            <div class="text-center pa-3">
              <v-pagination
                color="#052633"
                v-model="page"
                @input="fetchData"
                :length="last_page"
              ></v-pagination>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item>
        <manage-salary-today
          ref="salary_today"
          :dataGaji="dataSet"
          :limit="limit"
        />
      </v-tab-item>
    </v-tabs-items>
    <div class="pa-2">
      <dialog-large
        :d_large="d_large"
        :type="d_type"
        @close="d_large = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
import ManageSalaryToday from "./manageSalaryToday.vue";
export default {
  components: { bullet, ManageSalaryToday, NavbarProfile },
  name: "manageSalary",
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      menuLimit: false,
      loading: false,
      d_large: false,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      find: "",
      page: 1,
      limit: 10,
      dataSet: null,
      last_page: null,
      total_page: null,
      dataGajiToday: null,
      dataGajiToday_last_page: null,
      dataGajiToday_total_page: null,
      sort_menu: false,
      icon_sort: "mdi-sort-ascending",
      sort: "",
      optUnit: [],
      import_data: false,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Gaji",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Nama", value: "nama", sortable: false },
        { text: "Karyawan Id", value: "id" },
        {
          text: "Unit",
          value: "karyawan_gaji_unit_tipe_name",
          sortable: false,
        },
        { text: "Gaji", value: "gaji", sortable: false },
        {
          text: "Tanggal Berlaku",
          value: "effective_date",
        },
        { text: "Status", value: "status_bayar", sortable: false },
        { text: "Tanggal Pembayaran", value: "tanggal_bayar" },
        { text: "Action", value: "actions", sortable: false },
      ],
      tab: "",
    };
  },
  mounted() {
    this.fetchUnit();
    this.fetchData();
  },
  methods: {
    createData() {
      this.d_type = "c_salary";
      this.d_title = "Tambah Data Gaji";
      this.d_create = true;
      this.line = true;
      this.inventory = this.optUnit;
    },
    payData(item) {
      this.d_type = "c_salary_pay";
      this.d_title = "Pembayaran Gaji";
      this.d_create = true;
      this.line = true;
      this.inventory = item;
    },
    updateData(item) {
      this.d_type = "u_salary";
      this.d_title = "Update Data gaji";
      this.inventory = { data: item, optUnit: this.optUnit };
      this.line = true;
      this.d_create = true;
    },

    changeSort(item) {
      this.sort = item;
      this.sort_menu = false;
      this.fetchData();
    },
    fetchUnit() {
      let data = {
        path: `sysadmin/admin/gaji/karyawan/unit/tipe`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          this.optUnit = data.data;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/admin/gaji/karyawan?page[size]=${
          this.limit
        }&page[number]=${this.page}&filter[nama]=${this.find}&sort=${
          this.sort ? (this.icon_sort == "mdi-sort-ascending" ? "-" : "+") : ""
        }${this.sort}`,
      };
      this.$store
        .dispatch("officer/getData", data)
        .then((data) => {
          console.log(data);
          this.loading = false;
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    fetchDataGajiToday() {
      this.$refs.salary_today.fetchData();
    },
    changedesc(item) {
      this.icon_sort = item;
      this.fetchData();
    },
    changeFunction(item) {
      if (item == "export") {
        this.downloadeExport();
        this.importExport(item);
        this.import_data = false;
      } else {
        this.importExport(item);
        this.import_data = true;
      }
    },
    importExport(item) {
      if (item == "import") {
        this.inventory = [];
        this.d_type = "i_salary";
        this.d_title = "Import Gaji";
        this.d_large = true;
        this.line = true;
      }
    },
    downloadeExport() {
      let data = {
        path: `sysadmin/admin/gaji/karyawan/list-gaji/export`,
        body: "",
      };
      this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          window.open(data.data.path);
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/admin/gaji/karyawan/delete`,
        deleteType: "Gaji",
      };
      this.d_type = "delete";
      this.d_title = "Delete Gaji";
      this.d_small = true;
      this.line = true;
    },
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
</style>
