<template>
  <div>
    <v-row no-gutters class="mt-2 px-3" align="center">
      <v-col cols="12" md="4" class="py-2">
        <div class="d-flex align-center">
          <bullet :title="'List Pembayaran Gaji'" class="mr-2" />
          <v-menu transition="slide-y-transition" bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                @click="importExport(import_data)"
                class="bg_color1 rounded-lg"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <div class="d-flex align-center text-capitalize">
                  <v-icon class="mr-1">
                    {{ import_data ? "mdi-import" : "mdi-export" }}
                  </v-icon>
                  {{ import_data ? "Import" : "Export" }}
                  <v-icon class="ml-1"> mdi-menu-down </v-icon>
                </div>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="px-2">
                <v-btn
                  block
                  depressed
                  @click="changeFunction('export')"
                  class="rounded-lg"
                >
                  <div class="d-flex align-center text-capitalize">
                    <v-icon class="mr-1">mdi-export</v-icon>
                    Export
                  </div>
                </v-btn>
              </v-list-item>
              <v-list-item class="px-2">
                <v-btn
                  block
                  depressed
                  @click="changeFunction('import')"
                  class="rounded-lg"
                >
                  <div class="d-flex align-center text-capitalize">
                    <v-icon class="mr-1">mdi-import</v-icon>
                    Import
                  </div>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <v-row no-gutters align="center" justify="end">
          <!-- <div class="col_tollbar">
            <v-btn
              dark
              class="text-capitalize btn_tollbar rounded-lg bg_color1"
              @click="createData"
            >
              <v-icon class="mr-2" dark> mdi-plus </v-icon>
              Tambah
            </v-btn>
          </div> -->
          <div class="col_tollbar">
            <v-menu
              :close-on-content-click="false"
              v-model="sort_menu"
              transition="slide-y-transition"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  dark
                  color="#CFE1E1"
                  class="text-capitalize btn_tollbar rounded-lg"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#106967"> {{ icon_sort }}</v-icon>
                  <h4 class="ml-2" style="color: #106967">
                    {{
                      sort
                        ? sort == "karyawan_id"
                          ? "Karyawan Id"
                          : "Tangggal Berlaku"
                        : "Sort"
                    }}
                  </h4>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <div class="d-flex align-center">
                    <v-btn
                      @click="
                        changedesc(
                          icon_sort == 'mdi-sort-ascending'
                            ? 'mdi-sort-descending'
                            : 'mdi-sort-ascending'
                        )
                      "
                      block
                      class="text-capitalize"
                      text
                    >
                      <v-icon class="mr-2">{{ icon_sort }}</v-icon>
                      Sort
                    </v-btn>
                  </div>
                  <v-btn @click="sort_menu = false" icon fab x-small>
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    :class="
                      sort == 'karyawan_id'
                        ? 'text-capitalize grey'
                        : 'text-capitalize white'
                    "
                    :dark="sort == 'karyawan_id' ? true : false"
                    text
                    @click="changeSort('karyawan_id')"
                  >
                    ID Karyawan
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    :class="
                      sort == 'effective_date'
                        ? 'text-capitalize grey'
                        : 'text-capitalize white'
                    "
                    :dark="sort == 'effective_date' ? true : false"
                    text
                    @click="changeSort('effective_date')"
                  >
                    Tanggal Berlaku
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-skeleton-loader
      class="pa-2 elevation-0"
      type="table-tbody"
      v-if="loading"
    ></v-skeleton-loader>
    <div v-if="!loading && dataSet">
      <v-data-table
        :headers="headers"
        :items="dataSet"
        hide-default-footer
        :page.sync="page"
        :items-per-page="limit"
        class="elevation-0"
        :single-select="false"
      >
        <template v-slot:[`item.status_bayar`]="{ item }">
          <v-card
            flat
            :color="
              item.status_bayar == 0
                ? '#DC3545'
                : item.status_bayar == 1
                ? '#EDD57B'
                : '#26AF65'
            "
            dark
            class="text-capitalize pa-2 d-flex align-center justify-center"
          >
            {{
              item.status_bayar == 0
                ? "Belum"
                : item.status_bayar == 1
                ? "Tertunda"
                : "Bayar"
            }}
          </v-card>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="item.status_bayar == 2 ? true : false"
                  v-bind="attrs"
                  v-on="on"
                  small
                  icon
                  depressed
                  @click="payData(item)"
                >
                  <v-icon small> mdi-contactless-payment </v-icon>
                </v-btn>
              </template>
              <span>Bayar Gaji Karyawan</span>
            </v-tooltip>
          </div>
        </template>
        <!-- <template slot="body.append">
          <tr class="red--text">
            <th class="title">Totals</th>
            <th class="title"></th>
            <th class="title"></th>
            <th class="title"></th>
            <th class="title">
              Total Gaji 
            </th>
            <th class="title">Total dibayar</th>
            <th class="title">Maksimal dibayar</th>
          </tr>
        </template> -->
      </v-data-table>
      <div class="text-center pa-3">
        <v-pagination
          color="#052633"
          v-model="page"
          @input="fetchData"
          :length="last_page"
        ></v-pagination>
      </div>
    </div>
    <div class="pa-2">
      <dialog-large
        :d_large="d_large"
        :type="d_type"
        @close="d_large = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import bullet from "../../components/Etc/bullet.vue";
export default {
  components: { bullet },
  name: "manageSalaryToday",
  computed: {},
  props: ["dataGaji", "limit_page"],
  data() {
    return {
      loading: false,
      d_large: false,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      limit: this.limit_page,
      dataSet: null,
      last_page: null,
      total_page: null,
      line: "",
      find: "",
      page: 1,
      sort_menu: false,
      sort: "",
      import_data: false,
      icon_sort: "mdi-sort-ascending",
      headers: [
        { text: "Nama", value: "nama", sortable: false },
        { text: "ID Karyawan", value: "id" },
        {
          text: "Unit",
          value: "karyawan_gaji_unit_tipe_name",
          sortable: false,
        },
        { text: "Gaji", value: "gaji", sortable: false },
        {
          text: "Tanggal Berlaku",
          value: "effective_date",
        },
        { text: "Status", value: "status_bayar", sortable: false },
        { text: "Tanggal Pembayaran", value: "tanggal_bayar" },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    sumField(key) {
      // sum data in give key (property)
      return this.dataSet.reduce((a, b) => a + (b[key] || 0), 0);
    },
    payData(item) {
      this.d_type = "c_salary_pay";
      this.d_title = "Pembayaran Gaji";
      this.d_create = true;
      this.line = true;
      this.inventory = item;
    },
    createData() {
      this.d_type = "c_salary_pay";
      this.d_title = "Tambah Data Pembayaran Gaji";
      this.d_create = true;
      this.inventory = this.dataGaji;
      this.line = true;
    },
    updateData(item) {
      this.d_type = "u_salary_pay";
      this.d_title = "Update Data Pembayaran gaji";
      this.inventory = item;
      this.line = true;
      this.d_create = true;
    },

    changeSort(item) {
      this.sort = item;
      this.sort_menu = false;
      this.fetchData();
    },
    changedesc(item) {
      this.icon_sort = item;
      this.fetchData();
    },
    changeFunction(item) {
      if (item == "export") {
        this.downloadeExport();
        this.importExport(item);
        this.import_data = false;
      } else {
        this.importExport(item);
        this.import_data = true;
      }
    },
    importExport(item) {
      if (item == "import") {
        this.inventory = [];
        this.d_type = "i_salary_bayar";
        this.d_title = "Import Pembayaran Gaji";
        this.d_large = true;
        this.line = true;
      }
    },
    downloadeExport() {
      let data = {
        path: `sysadmin/admin/gaji/karyawan/export`,
        body: "",
      };
      this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          window.open(data.data.path);
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
    async fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/admin/gaji/karyawan/today?page[size]=${
          this.limit
        }&page[number]=${this.page}&filter[nama]=${this.find}&sort=${
          this.sort ? (this.icon_sort == "mdi-sort-ascending" ? "-" : "+") : ""
        }${this.sort}`,
      };
      this.$store
        .dispatch("officer/getData", data)
        .then((data) => {
          console.log(data);
          this.loading = false;
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/user/akun/delete`,
        deleteType: "Attendence",
      };
      this.d_type = "delete";
      this.d_title = "Delete Salary";
      this.d_small = true;
      this.line = true;
    },
  },
};
</script>

<style scoped></style>
